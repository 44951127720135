import React from 'react'
import PropTypes from 'prop-types'
import FilterTags from '../FilterTags'
import theme from '../../theme/blog-theme'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  tags: {
    [theme.breakpoints.down('md')]: {
      padding: '.5rem .7rem!important',
      fontSize: '1rem!important'
    }
  },
}));

const CardTags = (props) => {

  const {
    fontSize,
    padding,
    tag,
    filterCards,
    activeTag,
    setActiveTag,
    isFilter,
  } = props

  const classes = useStyles()

  const tagStyles = {
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    padding,
    margin: '0 .5rem .75rem 0',
    borderRadius: '30px',
    fontSize,
    cursor: 'pointer'
  }

  const handleClick = tag => {
    if(isFilter) {
      filterCards(tag)
      setActiveTag(tag)
    }
  }

  const styleClasses = `${activeTag ? 'active-tag' : ''} ${classes.tags}`
  
  return (
    <span 
      style={tagStyles}
      className={styleClasses}
      onClick={() => handleClick(tag)}
    >
      {tag}
    </span>
  )
}

export default CardTags

CardTags.propTypes = {
  tag: PropTypes.string,
  padding: PropTypes.string,
  fontSize: PropTypes.string,
  activeTag: PropTypes.bool,
  filterCards: PropTypes.func,
  setActiveTag: PropTypes.func
}

CardTags.defaultProps = {
  tag: '',
  fontSize: '.9rem',
  padding: '.2rem .6rem',
  activeTag: false
}