import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Grid, Box } from "@material-ui/core"
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../theme/blog-theme'
import Layout from "../components/layout"      
import FeaturedPostCard from "../components/featured-posts/featured-post-card"


const BlogPage  = (props) =>  {
   const { data } = props
   const { edges: posts } = data.allMarkdownRemark

   return (
     <div>
      <Layout>
        <ThemeProvider theme={theme}>
        <Box mt={8}>
          <Container maxWidth="lg" >
            <h1 style={{color: theme.palette.primary.main}}>All Posts</h1>
            <Grid container spacing={6} alignItems="stretch" >
              {(posts && 
                posts.map(({ node: post }, index) =>  (
                  
                  <Grid item xs={12} sm={6} md={4} key={index} style={{width: '100%'}}>
                    <FeaturedPostCard {...post} />
                  </Grid>
                ))
              )}
            </Grid>  
          </Container>
        </Box>
      </ThemeProvider>
       </Layout>
     </div>
   )
}

export default () => (
 <StaticQuery
   query={graphql`
     query BlogPageQuery {
       allMarkdownRemark(
         sort: { order: DESC, fields: [frontmatter___date] },
         filter: {
          frontmatter: {path: {ne: "blog/"}}
        }
       ) {
         edges {
           node {
             excerpt(pruneLength: 100)
             frontmatter {
              title
              path
              date(formatString: "MMMM DD, YYYY")
              tags
              image {
                src
                alt
                title
              }
             }
           }
         }
       }
     }
   `}
   render={data => <BlogPage data={data} />}
 />
)
