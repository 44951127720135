import React from 'react'
import './featured-post-card.css'
import CardTags from './card-tags'
import theme from '../../theme/blog-theme'

const FeaturedPostCard = (props) => {

  const post = props.frontmatter

  return (
    <div className="featured-card">
      <a href={post.path}>
        <div className="featured-card_image" style={{backgroundImage: 'url(' +  post.image.src + ')'}}>
        </div>
      </a>
      <div className="featured-card-inner">
        <div>
          <h3><a href={post.path} style={{color: theme.palette.primary.main}}>{post.title}</a></h3>
          <p>
            {props.excerpt}
            <a href={post.path} className="read-more" style={{color: theme.palette.primary.main}}>
              Read More...
            </a>
          </p>
        </div>

        {(post.tags &&
          <div>
            <p className="featured-card-tag">Tags:</p>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
              {post.tags && post.tags.map(tag => <CardTags tag={tag} key={tag}/>)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FeaturedPostCard