import React, { useState } from 'react'
import { StaticQuery, graphql } from "gatsby"
import { Grid, Container} from "@material-ui/core"
import CardTags from './featured-posts/card-tags'
import theme from '../theme/blog-theme'
import './filter-tags.css'

function FilterTags(props) {
  const { data, filterCards } = props
  const { allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { edges } = allMarkdownRemark

  const allTags = edges.map(node => {
    return [...node.node.frontmatter.tags]
  })

  const [activeTag, updateActiveTag] = useState('')

  let tagArray = []

  tagArray = tagArray.concat(...allTags).filter((elem, index, self) => {
    return self.indexOf(elem) === index;
  })

  return (
    <Container maxWidth="sm">
      <Grid container alignItems="center" justify="center">
        <h3 className="filter-header" style={{color: theme.palette.primary.main}}>Filters</h3>
      </Grid>
      <Grid 
        container 
        alignItems="stretch" 
        justify="center" 
        className="filter-container"
        style={{marginBottom: theme.spacing(6)}} 
      >
        {tagArray && (
          tagArray.map((tag, index) => {
            return <Grid 
                      item 
                      xs={4}
                      sm={4} 
                      md={3} 
                      key={index} 
                      style={{width: '100%', textAlign: 'center'}}
                    >
                    <CardTags 
                      tag={tag}
                      isFilter={true}
                      activeTag={tag === activeTag }
                      setActiveTag={updateActiveTag}
                      filterCards={filterCards}
                      padding='.6rem .8rem'
                      fontSize= '1.1rem'
                    />
                  </Grid>
          })
        )}
      </Grid>
    </Container>
  )
}

// Currently hardcoding in the "work" tags, see what I can do about variablizing
// That value
export default (props) => (
 <StaticQuery
   query={graphql`
     query FilterTagsQuery {
      allMarkdownRemark(
         sort: { order: DESC, fields: [frontmatter___date] },
         filter: {
          frontmatter: {
            path: {regex: "/work/"},
          }
        }
       ) {
         edges {
           node {
             frontmatter {
              tags
             }
           }
         }
       }
     }
   `}
   render={data => <FilterTags data={data} {...props}/>}
 />
)

